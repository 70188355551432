import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { FetchAtomic, setActiveIndex } from "Redux/feature/StudentHome";
import { Close } from "@mui/icons-material";
import { useEffect, useRef } from "react";
import { useWirisParser } from "hooks/useWiris";
import { isBackFromAtomicData } from "Redux/feature/LibrarySlice";
import { getCookie } from "views/common/cookieUtils";
import { setMathFlag } from "Redux/feature/Questions";
import Loader from "views/common/loader";

type AtomicOnlineVideoType = {
  text?: string;
  title?: string;
  assignmentId?: string;
  type?: string;
  id?: string;
  videoURL?: string;
  handleIncrementIndex: (
    studentId: string | undefined,
    assignmentId: string,
    type: string,
    id: string
  ) => void;
  handleDecrementIndex: () => void;
};

function AtomicOnlineVideo({
  assignmentId,
  text,
  title,
  id,
  type,
  videoURL,
  handleDecrementIndex,
  handleIncrementIndex,
}: AtomicOnlineVideoType) {
  const [searchParams, setSerchParams] = useSearchParams();

  const atomicSideBar: any = searchParams.get("atomicSideBar");

  const navigate = useNavigate();
  const questionDialogRef: React.MutableRefObject<any> = useRef<any>(null);

  const atomic_id = searchParams.get("atomic_id");
  const assignment_id = searchParams.get("assignment_id");
  const assignmentData: any = localStorage.getItem("assignment");
  const assignment: any = JSON.parse(assignmentData);
  const atomicType = searchParams.get("atomic_type");
  const location = useLocation();
  const isPreviewPath = location.pathname.includes("/preview");
  const singleAtomic = useAppSelector(
    (state) => state.StudentsHomeData.singleAtomic
  );
  const mathFlag: boolean = useAppSelector((state) => state.Questions.mathFlag);

  const user_id = getCookie("id");

  const assignmentIndex = useAppSelector(
    (state) => state.StudentsHomeData.activeAtomicIndex
  );
  const dispatch = useAppDispatch();
  const user_type = getCookie("user_type");
  const currentIndex = assignment?.findIndex((item: any) => {
    return (
      item.types === "atomic" &&
      item.atomic[0]?.types.name === atomicType &&
      item.atomic_container_id == atomic_id
    );
  });
  const previousElement = assignment ? assignment[currentIndex - 1] : null;
  useEffect(() => {
    dispatch(setActiveIndex(currentIndex));
  }, [atomic_id]);

  useEffect(() => {
    dispatch(
      FetchAtomic({
        atomic_unit_id: atomic_id,
        assignment_id: assignment_id,
      })
    );
  }, [atomic_id, assignment_id]);

  useWirisParser(questionDialogRef);

  const lastTabNumber = localStorage.getItem("tabNumber");
  const parsedTabNumber = lastTabNumber ? parseInt(lastTabNumber) : null;

  const handelBackToLast = () => {
    if (user_type === "Teacher") {
      navigate(-1);
    } else {
      if (parsedTabNumber === 0) {
        localStorage.setItem("tabNumber", "0");
        dispatch(isBackFromAtomicData(false));
        navigate(-1);
      } else {
        localStorage.setItem("tabNumber", "1");
        dispatch(isBackFromAtomicData(true));
        navigate("/student/library");
      }
    }
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://kidum-me.h5p.com/js/h5p-resizer.js";
    script.charset = "UTF-8";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, [atomic_id]);

  useEffect(() => {
    const hasMathTag = (text: string) => {
      const mathTagRegex = /<math/;
      return mathTagRegex.test(text);
    };

    const shouldShowLoader = () => {
      if (hasMathTag(singleAtomic.text)) {
        return true;
      }
      return false;
    };

    const showLoader = shouldShowLoader();
    dispatch(setMathFlag(showLoader));
  }, [singleAtomic.text]);

  return (
    <>
      <Grid container wrap="nowrap">
        <Grid item>{/* <PracticeStepsList currentStep={0} /> */}</Grid>
        {mathFlag && <Loader />}
        <Grid item xs={12}>
          <Stack direction="row">
            <Stack flexGrow={1} height="100%">
              <Box
                sx={{
                  backgroundColor: "#F8F9FA",
                  height: {
                    xs: "calc(100vh - 190px)",
                    md: "calc(100vh - 181px)",
                  },
                  minHeight: { xs: "calc(100vh - 167px)", md: "400px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  paddingTop: { xs: "100px", md: "20px" },
                  overflow: "hidden",
                  paddingBottom: "50px",
                }}
              >
                <Stack
                  alignItems="center"
                  sx={{
                    height: "calc(100vh - 160px)",
                    overflowY: "auto",
                    marginTop: { xs: 0, md: "90px" },
                    paddingBottom: { xs: "70px", md: 0 },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: 24, fontWeight: 700, mb: 1 }}
                  >
                    {title ? title : singleAtomic.title}
                  </Typography>
                  <Box width={{ xs: "100%", lg: "674px" }} mb={1}>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: text ? text : singleAtomic.text,
                        }}
                      ></div>
                    </Typography>
                  </Box>

                  {singleAtomic?.types?.name === "video" && (
                    <ReactPlayer
                      className="react-player"
                      autoPlay
                      controls
                      url={videoURL ? videoURL : singleAtomic.link}
                      sx={{
                        marginTop: 15,
                        marginBottom: 64,
                        height: {
                          xs: "150px",
                          sm: "420px",
                          md: "420px",
                          lg: "420px",
                        },
                        width: {
                          xs: "300px",
                          sm: "400px",
                          md: "500px",
                          lg: "743px",
                        },
                      }}
                    />
                  )}

                  {singleAtomic?.types?.name === "online video" && (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        paddingBottom: "56.25%",
                        maxWidth: "743px",
                        marginTop: 2,
                        marginBottom: 6,
                        borderRadius: "6px",
                        boxShadow: "0 0px 8px 2px rgba(0,0,0,0.05)",
                      }}
                    >
                      <iframe
                        src={`${videoURL ? videoURL : singleAtomic.link}`}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          border: 0,
                          borderRadius: "6px",
                        }}
                        aria-label="מבנה המשפט ב"
                        frameBorder="0"
                        allowFullScreen
                        allow="autoplay; geolocation; microphone; camera; midi; encrypted-media"
                      ></iframe>
                    </Box>
                  )}
                </Stack>
                {atomicSideBar !== false && atomicSideBar !== null && (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        height: "40px",
                        padding: "9px 22px",
                        borderRadius: "8px",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%)",
                        bottom: 15,
                        display: { xs: "none", md: "block" },
                      }}
                      onClick={handelBackToLast}
                    >
                      <FormattedMessage id="backToTheLibrary" />
                    </Button>
                  </>
                )}
                {atomicSideBar !== false && atomicSideBar !== null && (
                  <>
                    <Box
                      sx={{
                        height: "40px",
                        padding: "9px 22px",
                        borderRadius: "8px",
                        position: "absolute",
                        left: 0,
                        top: "10%",
                        display: { xs: "block", md: "none" },
                        color: "#7A49B9",
                      }}
                    >
                      <Close onClick={handelBackToLast} />
                    </Box>
                  </>
                )}
              </Box>
              <Box
                style={
                  atomicSideBar === false || atomicSideBar === null
                    ? { display: "flex" }
                    : { display: "none" }
                }
                justifyContent="center"
                borderTop={1}
                borderColor="divider"
                sx={{
                  backgroundColor: "#FFF",
                  position: "fixed",
                  bottom: 0,
                  width: ["-webkit-fill-available", "-moz-available"],
                }}
              >
                {assignmentIndex != 0 &&
                previousElement?.container?.[0]?.container_type_id != "11" &&
                !isPreviewPath ? (
                  <>
                    <Button
                      variant="outlined"
                      sx={{
                        my: "15px",
                        height: "40px",
                        padding: "9px 22px",
                        borderRadius: "8px",
                        mx: "8px",
                      }}
                      onClick={handleDecrementIndex}
                    >
                      <FormattedMessage id="previous-stage" />
                    </Button>
                  </>
                ) : (
                  <></>
                )}

                {!isPreviewPath ? (
                  <>
                    {" "}
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "primary",
                        my: "15px",
                        height: "40px",
                        padding: "15px 25px",
                        borderRadius: "8px",
                        mx: "8px",
                      }}
                      onClick={() => {
                        if (handleIncrementIndex) {
                          handleIncrementIndex(
                            user_id,
                            assignmentId ? assignmentId : assignment_id || "",
                            type ? type : singleAtomic?.types?.name || "",
                            id ? id : singleAtomic?.id || ""
                          );
                        }
                      }}
                    >
                      <FormattedMessage id="next-step" />
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default AtomicOnlineVideo;
